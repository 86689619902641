import React, { useState, useEffect, useCallback, useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";


let texts = {
    1: 'Транспортно - экспедиторская компания PSG - logistics, команда профессионалов с международной экспертизой, оказывающих транспортные услуги по всему миру уже более 20 лет.',
    2: 'Контейнерный терминал «Промышленный» ориентирован на обработку и хранение грузов, прибывающих и убывающих автотранспортом. На территории размещены: контейнерная площадка, склад закрытого хранения, площадка открытого хранения. Территория работает круглосуточно',
    3: 'Компания PSG - trade оказывает полный комплекс услуг по сопровождению внешнеторговых операций «под ключ»',
    4: 'Компания PSG – IT разрабатывает цифровые сервисы для оптимизации транспортной и международной логистики. Разработанные продукты позволяют сократить транспортные расходы и управлять даже самой сложной логистикой',
}

let titles = {
    1: 'Международные перевозки',
    2: 'Контейнерный терминал',
    3: 'ВЭД консалтинг',
    4: 'Цифровая логистика',
}

let links = {
    1: 'www.psg.su',
    2: 'www.cargoterminal-i.com',
    3: 'www.psg.trade',
    4: 'www.it-psg.com',
}

const Slider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeAutoplay, setActiveAutoplay] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const autoplayRef = useRef();

    /*--------------------
    SETTINGS
    --------------------*/
    const settings = {
        maxItems: 4,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 4000
    };

    /*--------------------
    METODI
    --------------------*/
    /* Go To */
    const goTo = useCallback(
        index => {
            if (!isAnimating) {
                setCurrentIndex(index);
                setIsAnimating(true);

                setTimeout(() => {
                    setIsAnimating(false);
                }, settings.speed);
            }
        },
        [isAnimating, currentIndex]
    );

    /* Go Next */
    const goNext = () => {
        goTo(currentIndex >= settings.maxItems - 1 ? 0 : currentIndex + 1);
    };

    /* Go Prev */
    const goPrev = () => {
        goTo(currentIndex <= 0 ? settings.maxItems - 1 : currentIndex - 1);
    };

    /* Play Timer */
    const playTimer = () => {
        setActiveAutoplay(true);
    };

    /* Pause Timer */
    const pauseTimer = () => {
        setActiveAutoplay(false);
    };

    /*--------------------
    HOOKS
    --------------------*/
    useEffect(() => {
        if (settings.autoplay && activeAutoplay) {
            clearTimeout(autoplayRef.current);
            autoplayRef.current = setTimeout(() => {
                goNext();
            }, settings.autoplaySpeed);
        }
    }, [currentIndex, activeAutoplay, isAnimating]);

    /*--------------------
    COMPONENTI
    --------------------*/
    /* Next Btn */
    const nextBtn = () => {
        return (
            <button
                className="next"
                onMouseEnter={pauseTimer}
                onMouseLeave={playTimer}
                onClick={() => goNext()}
            >
                next
            </button>
        );
    };

    /* Prev Btn */
    const prevBtn = () => {
        return (
            <button
                className="prev"
                onMouseEnter={pauseTimer}
                onMouseLeave={playTimer}
                onClick={() => goPrev()}
            >
                prev
            </button>
        );
    };

    /* Dots */
    const dots = () => {
        const totalItems = Array.apply(null, Array(settings.maxItems));
        const dotsButtons = totalItems.map((item, index) => {
            return (
                <button
                    className="dot"
                    key={index.toString()}
                    onMouseEnter={pauseTimer}
                    onMouseLeave={playTimer}
                    onClick={() => goTo(index)}
                >
                    {index + 1}
                </button>
            );
        });
        return <div className="dots">{dotsButtons}</div>;
    };

    /* Slide */
    const slide = index => {
        index += 1;
        return (
            <div className='slide-main'>
                <div className="slide-images-block">
                    <a href={`https://${links[index]}`}>
                        <img
                            src={process.env.PUBLIC_URL + `/carousel-${index}.png`}
                            className="slide-image"
                        />
                    </a>
                </div>
                <div className="slide-content">
                    <h2>{titles[index]}</h2>
                    <div className="slide-text">{texts[index]}</div>

                    <a href={`https://${links[index]}`} className="slide-link">{links[index]}</a>
                </div>
            </div>

        );
    };

    /*--------------------
    RENDERING
    --------------------*/
    return (

        <div className="slider">
            {slide(currentIndex)}
        </div>

    );
};

export default Slider;