import React from 'react'
import './SubHeaderLink.css'

const SubHeaderLink = (props) => {

    return (
        <a href={props.link} className='SubHeaderLink-content'>
            <img
                loading="lazy"
                src={process.env.PUBLIC_URL + '/up-right-arrow.svg'}
                className="SubHeaderLink-img"
            />
            <div className="">{props.text}</div>
        </a>
    )
}

export default SubHeaderLink