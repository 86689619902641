import SubHeaderLink from "./sub-header-link/SubHeaderLink"
import React, { useContext } from 'react'
import './Header.css'
import Container from "../container/Container"
import HamburgerButton from "./menu-button/MenuButton"
import { MenuContext } from "./menu-context/MenuContext"

function Header(props) {
    return (
        <div className="header-main">
            <div className="sub-header">
                <Container>
                    <div className="sub-header-content">
                        <SubHeaderLink link={"https://psg.su"} text={"Международные перевозки"} />
                        <SubHeaderLink link={"https://cargoterminal-i.com"} text={"Контейнерный терминал"} />
                        <SubHeaderLink link={"https://psg.trade"} text={"ВЭД консалтинг"} />
                        <SubHeaderLink link={"https://it-psg.com"} text={"Цифровая логистика"} />
                    </div>
                </Container>
            </div>

            <div className="header">
                <Container className="header-container">
                    <div className="header-content">
                        <a href="https://psg.services">
                            <img
                                loading="lazy"
                                src={process.env.PUBLIC_URL + '/logo.svg'}
                                className="img-3"
                            />
                        </a>

                        <div className="header-menu">
                            <a href="tel:+7 (843) 514-88-66" className="header-menu-phone">+7 (843) 514-88-66</a>
                            <a href="mailto:mail@psg.services" className="header-menu-mail">
                                <img
                                    loading="lazy"
                                    src={process.env.PUBLIC_URL + '/mail.svg'}
                                    className="img-3"
                                />
                                <div className="">mail@psg.services</div>
                            </a>
                            <div className="header-menu-lang">
                                <div className="">RU</div>
                                <img
                                    loading="lazy"
                                    src={process.env.PUBLIC_URL + '/play.svg'}
                                    className="img-3"
                                />
                            </div>

                        </div>
                        <HamburgerButton />
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Header