import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MenuContext } from '../menu-context/MenuContext';
//import arrow from '../arrow.svg';
import './SideMenu.css';

const Menu = styled.nav`
  position: fixed;
  top: 100px;
  right: 0px;
  bottom: 0px;
  z-index: 293;
  display: block;
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
  padding-top: 10px;
  padding-right: 0px;
  align-items: stretch;
  background-color: #fff;
  transform: translateX(+100%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media screen and (min-width: 1000px) {
    display: none;
}

  ${props =>
        props.open &&
        css`
      transform: translateX(0);
    `}
`;

export const MenuLink = styled.a`
  position: relative;
  display: block;
  text-align: left;
  max-width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 16%;
  padding-right: 30%;
  background-image: url(${process.env.PUBLIC_URL + '/up-right-arrow.svg'});
  background-position: 90% 50%;
  background-size: 50px;
  background-repeat: no-repeat;
  transition: background-position 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-decoration: none;
  color: #000;
  font-size: 1.2em;
  line-height: 120%;
  font-weight: 500;

  :hover {
    background-position: 90% 50%;
  }
`;

export const SideMenu = ({ children }) => {
    const { isMenuOpen } = useContext(MenuContext);

    return <Menu open={isMenuOpen}>{children}</Menu>;
};

SideMenu.propTypes = {
    children: PropTypes.node,
};

SideMenu.defaultProps = {
    children: (
        <>
            <div className='side-menu-flex'>
                <div>
                    <MenuLink href="https://psg.su">Международные перевозки</MenuLink>
                    <MenuLink href="https://cargoterminal-i.com">Контейнерный терминал</MenuLink>
                    <MenuLink href="https://psg.trade">ВЭД консалтинг</MenuLink>
                    <MenuLink href="https://it-psg.com">Цифровая логистика</MenuLink>
                </div>
                <div className='header-menu-side'>
                    <h1>Контакты</h1>
                    <div className='header-menu-side-contacts'>
                        <a href="tel:+7 (843) 514-88-66" className="header-menu-phone">+7 (843) 514-88-66</a>
                        <a href="mailto:mail@psg.services" className="header-menu-mail">
                            <img
                                loading="lazy"
                                src={process.env.PUBLIC_URL + '/mail.svg'}
                                className="img-3"
                            />
                            <div className="">mail@psg.services</div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    ),
};