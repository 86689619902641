import React, { useState, useContext } from 'react'
import './Content.css'
import Container from '../container/Container'
import ContentLink from './content-link/ContentLink'
import Slide from './slide/Slide'
import { SideMenu } from '../header/side-menu/SideMenu'
import { MenuContext } from '../header/menu-context/MenuContext';
import Slider from './slide/slide-test'

const maxSlides = 4

const Content = () => {
    const [activeSlide, setActiveSlide] = useState(1);

    const nextSlideHandler = () => {
        setActiveSlide(
            activeSlide >= maxSlides ?
                1 : activeSlide + 1
        )
    }

    return (
        <>
            <SideMenu />
            <div className="main-content" >
                <div className="main-content-layout">
                    <Container className="content-container">
                        <div className="content-block">
                            <div className="main-text">
                                <h1 className='page-title'>PSG – cинхронизация
                                    мировой торговли</h1>
                                <p className="page-text">
                                    Группа компаний PSG оказывает полный спектр услуг
                                    в области внешнеэкономической торговли:
                                    международные перевозки, таможенное оформление, сертификация товаров,
                                    сборные грузы, разработка цифровых логистических сервисов
                                </p>
                            </div>
                            <div className="main-links">
                                <ContentLink
                                    img={"worldwide-shipping.svg"}
                                    text={"Международные перевозки"}
                                    link_text={"www.psg.su"}
                                    link={"https://psg.su"}
                                />
                                <ContentLink
                                    img={"terminal.svg"}
                                    text={"Контейнерный терминал"}
                                    link_text={"www.cargoterminal-i.com"}
                                    link={"https://cargoterminal-i.com"}
                                />
                                <ContentLink
                                    img={"consulting.svg"}
                                    text={"ВЭД консалтинг"}
                                    link_text={"www.psg.trade"}
                                    link={"https://psg.trade"}
                                />
                                <ContentLink
                                    img={"digital-logistics.svg"}
                                    text={"Цифровая логистика"}
                                    link_text={"www.it-psg.com"}
                                    link={"https://it-psg.com"}
                                />
                            </div>
                        </div>
                        <div className="content-block right-block">
                            <Slider />
                        </div>
                    </Container>
                </div>

            </div>
        </>

    )
}

export default Content