import React, {useContext} from 'react'
import Container from '../container/Container'
import './Footer.css'
import { MenuContext } from '../header/menu-context/MenuContext';


function Footer() {
    return (

        <div className='footer-main'>
            <Container>
                <div className="footer-content">
                    <div className='footer-left-side'>
                        <span>© 2023 PSG LLC</span> <a href="https://psg.su/politika-konfidentsialnosti/">Политика конфиденциальности</a>

                    </div>
                    <div className='footer-right-side'>
                        Разработки и продвижение сайтов <b> Studio 512</b>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footer