import React from 'react'
import "./ContentLink.css"

const ContentLink = (props) => {
    return (
        <div className='ContentLink-main'>
            <div className="ContentLink-logo">
                <img
                    //loading="lazy"
                    src={process.env.PUBLIC_URL + `/${props.img}`}
                    className="ContentLink-img"
                />
            </div>
            <div className="ContentLink-content">
                <div className="ContentLink-text">
                    {props.text}
                </div>
                <a href={props.link} className="ContentLink-link">
                    {props.link_text}
                </a>
            </div>
        </div>
    )
}

export default ContentLink