import React, {useContext} from 'react'
import Header from './header/Header'
import './index.css'
import Footer from './footer/Footer'
import Content from './content/Content'
import NavState from "./header/menu-context/MenuContext"

const App = () => {

    return (
        <NavState>
            <Header />
            <Content/>
            <Footer />
        </NavState>
    )
}

export default App